@import url(https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;600&display=swap);
*{
	font-family: Open Sans;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
}

body{
	background: #f5f5f5;
}

.style_point__2D0Cj{
	margin-left: 35px;
}
.style_wrooom__1txIQ {
	margin-left: 10px;
	transition: margin 1s ease-out 0.5s;
}
.style_hover_collapse__zL0yH .style_sidebar__zblvT{
	width: 96px;
}

.style_hover_collapse__zL0yH .style_sidebar__zblvT ul li a .style_text__1fY7u{
	display: none;
}

.style_sidebar__zblvT{
	position: fixed;
	/* top: 60px; */
	left: 0;
	width: 242px;
	height: 100%;
	background: #E9D9FF;
	border-radius: 0px 5px 5px 0;
	box-shadow: -2px -2px 2px 1px rgba(120, 60, 198, 0.3), 2px 2px 2px 1px rgba(120, 60, 198, 0.3)
}

.style_sidebar__zblvT ul li a{
	display: block;
	padding: 12px 25px;
	/* border-bottom: 1px solid #0e94d4; */
	color: #0e94d4;
	transition: all 0.1s ease;
}

.style_sidebar__zblvT ul li a .style_icon__230vY{
	font-size: 18px;
	vertical-align: middle;
	transition: background 0.1s ease;
	margin: -3px;
}

.style_sidebar__zblvT ul li a .style_text__1fY7u{
	margin-left: 10px;
	color: #000000;
	/* text-transform: uppercase; */
	/* letter-spacing: 2px; */
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 27px;
	white-space: nowrap;
}

.style_active__Box3P{
	padding-top: 4px;
}
.style_menu_item__2hU3g{
	display: flex;
}
.style_item_conteiner__2d-VS{
	display: flex;
	flex-direction: row;
}
.style_sidebar__zblvT ul li a:hover{
	background: #dec9fa;
	color: #fff;
}
.style_click_collapse__1j2fR .style_sidebar__zblvT{
	transition: all 0.1s ease;
}

.style_main_container__2yhYJ {
	/* margin-top: 60px; */
	margin-left: 100px;
	padding: 25px;
	width: calc(100% - 100px);
	min-height: 962px;
	display: flex;
    flex-direction: column;
}

.style_main_container__2yhYJ .style_content__Ekh_j{
	background: #fff;
	padding: 25px;
	margin-bottom: 25px;
	text-align: justify;
}

.style_click_collapse__1j2fR .style_main_container__2yhYJ{
	width: calc(100% - 250px);
	margin-left: 250px;
	transition: all 0.1s ease;
}

.style_mediabrain_footer__3MeL3 {
	display: flex;
	justify-content: center;
    margin-top: auto;
    padding-top: 15px;
}

.style_mediabrain_footer_text__20XPj {
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.style_admin__3kWXS {
	display: none;
}
a[id="fLink"]:link {
    color: #783CC6;
}

a[id="fLink"]:visited {
	color: #783CC6;
}

a[id="fLink"]:hover {
    color: #783CC6;
}

a[id="fLink1"]:link {
    color: #783CC6;
}

a[id="fLink1"]:visited {
    color: #783CC6;
}

a[id="fLink1"]:hover {
    color: #783CC6;
}
.style_main_container__T4KE3{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px; 
    width: 98%;
}
.style_company_logo__XUKYa{
    /* padding-right: 422px; */
}
.style_main_container_menu__1Mhtm{
    font-family: Open Sans;
    font-style: normal;
    font-Weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex; 
    justify-content: space-between;
    width: 506px; 
    height: 27px;
}
.style_menu_item__14BQ9{
    /* padding-left: 60px; */
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_user_container__3dxCn{
    display: flex;
    justify-content: space-around;
    width: 265px;
    height: 27px; 
    /* padding-left: 349px;  */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019; 
    white-space: nowrap; 
}
.style_user_icon__23Vb3{
    background: #E9D9FF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF; 
    /* margin-right: 17px; */
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 84px;
    margin-top: -10px;
    opacity: 0.6;
    border: 0.5px solid #783CC6;
}
.style_user_text__2ri1d{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_logout_icon__205K1{
    /* padding-left: 30px; */
    cursor: pointer;
}
a:active{
    color: #783CC6;
}
.style_active__3nhGN{
    font-weight: 600;
}
.gm-style-iw + div {
    display: none;
}
.gm-ui-hover-effect {
    display: none !important;
}
div[id="panel1bh-header"].MuiAccordionSummary-root.Mui-expanded {
    height: 17px;
    min-height: 17px !important;
}
div[id="panel2bh-header"].MuiAccordionSummary-root.Mui-expanded {
    height: 17px;
    min-height: 17px !important;
}

.style_main_container__dYR6K{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px; 
    width: 98%;
}
.style_company_logo__17p8S{
    /* padding-right: 422px; */
}
.style_main_container_menu__1ICWK{
    font-family: Open Sans;
    font-style: normal;
    font-Weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex; 
    justify-content: space-between;
    width: 506px; 
    height: 27px;
}
.style_menu_item__3x4z3{
    /* padding-left: 60px; */
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_user_container__20cRe{
    display: flex;
    justify-content: space-around;
    width: 265px;
    height: 27px; 
    /* padding-left: 349px;  */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019; 
    white-space: nowrap; 
}
.style_user_icon__3Vacm{
    background: #E9D9FF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF; 
    /* margin-right: 17px; */
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 84px;
    margin-top: -10px;
    opacity: 0.6;
    border: 0.5px solid #783CC6;
}
.style_user_text__1KMCu{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_logout_icon__mrQNp{
    /* padding-left: 30px; */
    cursor: pointer;
}
a:active{
    color: #783CC6;
}
.style_active__3gRdX{
    font-weight: 600;
}
div[id="panelOpt1bh-content"] > .MuiAccordionDetails-root {
    display: block !important;
    padding: 8px 16px 16px;
    width: 100%;
}
.style_main_container__1AHzO {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    width: 98%;
}

.style_company_logo__3gSjn {
    /* padding-right: 422px; */
}

.style_main_container_menu__31KSD {
    font-family: Open Sans;
    font-style: normal;
    font-Weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    justify-content: space-between;
    width: 506px;
    height: 27px;
}

.style_menu_item__may6U {
    /* padding-left: 60px; */
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}

.style_user_container__MHJSo {
    display: flex;
    justify-content: space-around;
    width: 265px;
    height: 27px;
    /* padding-left: 349px;  */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
    white-space: nowrap;
}

.style_user_icon__RVEvp {
    background: #E9D9FF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    /* margin-right: 17px; */
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 84px;
    margin-top: -10px;
    opacity: 0.6;
    border: 0.5px solid #783CC6;
}

.style_user_text___6axe {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}

.style_logout_icon__1wA9z {
    /* padding-left: 30px; */
    cursor: pointer;
}

a:active {
    color: #783CC6;
}

.style_active__2Mnzz {
    font-weight: 600;
}
div[id="panel1rtg-header"].MuiAccordionSummary-root.Mui-expanded {
    height: 17px;
    min-height: 17px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.gm-style-iw + div {
    display: none;
}
.gm-ui-hover-effect {
    display: none !important;
}
.style_main_container__3t3a3{
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px; 
    width: 98%;
}
.style_company_logo__1h7wX{
    /* padding-right: 422px; */
}
.style_main_container_menu__3aTyE{
    font-family: Open Sans;
    font-style: normal;
    font-Weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex; 
    justify-content: space-between;
    width: 506px; 
    height: 27px;
}
.style_menu_item__3UM5J{
    /* padding-left: 60px; */
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_user_container__2OlC9 {
    display: flex;
    justify-content: space-around;
    width: 265px;
    min-width: 265px;
    height: 27px;
    /* padding-left: 349px;  */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
    white-space: nowrap;
}
.style_user_icon__3fWE6{
    background: #E9D9FF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF; 
    /* margin-right: 17px; */
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 84px;
    margin-top: -10px;
    opacity: 0.6;
    border: 0.5px solid #783CC6;
}
.style_user_text__321z7{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_logout_icon__3jYp_{
    /* padding-left: 30px; */
    cursor: pointer;
}
a:active{
    color: #783CC6;
}
.style_active__2Njt0{
    font-weight: 600;
}
div[id="panel1dbrd-header"].MuiAccordionSummary-root.Mui-expanded {
    height: 54px;
    min-height: 54px !important;
}

div[id="panel2dbrd-header"].MuiAccordionSummary-root.Mui-expanded {
    height: 54px;
    min-height: 54px !important;
}

.style_main_container__1kvMt{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px; 
    width: 98%;
}
.style_company_logo__2BZtz{
    /* padding-right: 422px; */
}
.style_main_container_menu__32LsS{
    font-family: Open Sans;
    font-style: normal;
    font-Weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex; 
    justify-content: space-between;
    width: 506px; 
    height: 27px;
}
.style_menu_item__2yksR{
    /* padding-left: 60px; */
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_user_container__3CiFO{
    display: flex;
    justify-content: space-around;
    width: 265px;
    height: 27px; 
    /* padding-left: 349px;  */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019; 
    white-space: nowrap; 
}
.style_user_icon__1eCKj{
    background: #E9D9FF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF; 
    /* margin-right: 17px; */
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 84px;
    margin-top: -10px;
    opacity: 0.6;
    border: 0.5px solid #783CC6;
}
.style_user_text__2ofN4{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_logout_icon__3kFbL{
    /* padding-left: 30px; */
    cursor: pointer;
}
a:active{
    color: #783CC6;
}
.style_active__3cEJY{
    font-weight: 600;
}
.style_main_container__14Po6{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px; 
    width: 98%;
}
.style_company_logo__30tjN{
    /* padding-right: 422px; */
}
.style_main_container_menu__LAS-U{
    font-family: Open Sans;
    font-style: normal;
    font-Weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex; 
    justify-content: space-between;
    width: 506px; 
    height: 27px;
}
.style_menu_item__6ePUl{
    /* padding-left: 60px; */
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_user_container__ti8yZ{
    display: flex;
    justify-content: space-around;
    width: 265px;
    height: 27px; 
    /* padding-left: 349px;  */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019; 
    white-space: nowrap; 
}
.style_user_icon__HjSPW{
    background: #E9D9FF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF; 
    /* margin-right: 17px; */
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 84px;
    margin-top: -10px;
    opacity: 0.6;
    border: 0.5px solid #783CC6;
}
.style_user_text__1dEWL{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.style_logout_icon__13CzD{
    /* padding-left: 30px; */
    cursor: pointer;
}
a:active{
    color: #783CC6;
}
.style_active__Bi29U{
    font-weight: 600;
}
.style_back_to_permission__2XNyJ{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    color: #000000;
}
