@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;600&display=swap');

*{
	font-family: Open Sans;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
}

body{
	background: #f5f5f5;
}

.point{
	margin-left: 35px;
}
.wrooom {
	margin-left: 10px;
	transition: margin 1s ease-out 0.5s;
}
.hover_collapse .sidebar{
	width: 96px;
}

.hover_collapse .sidebar ul li a .text{
	display: none;
}

.sidebar{
	position: fixed;
	/* top: 60px; */
	left: 0;
	width: 242px;
	height: 100%;
	background: #E9D9FF;
	border-radius: 0px 5px 5px 0;
	box-shadow: -2px -2px 2px 1px rgba(120, 60, 198, 0.3), 2px 2px 2px 1px rgba(120, 60, 198, 0.3)
}

.sidebar ul li a{
	display: block;
	padding: 12px 25px;
	/* border-bottom: 1px solid #0e94d4; */
	color: #0e94d4;
	transition: all 0.1s ease;
}

.sidebar ul li a .icon{
	font-size: 18px;
	vertical-align: middle;
	transition: background 0.1s ease;
	margin: -3px;
}

.sidebar ul li a .text{
	margin-left: 10px;
	color: #000000;
	/* text-transform: uppercase; */
	/* letter-spacing: 2px; */
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 27px;
	white-space: nowrap;
}

.active{
	padding-top: 4px;
}
.menu_item{
	display: flex;
}
.item_conteiner{
	display: flex;
	flex-direction: row;
}
.sidebar ul li a:hover{
	background: #dec9fa;
	color: #fff;
}
.click_collapse .sidebar{
	transition: all 0.1s ease;
}

.main_container {
	/* margin-top: 60px; */
	margin-left: 100px;
	padding: 25px;
	width: calc(100% - 100px);
	min-height: 962px;
	display: flex;
    flex-direction: column;
}

.main_container .content{
	background: #fff;
	padding: 25px;
	margin-bottom: 25px;
	text-align: justify;
}

.click_collapse .main_container{
	width: calc(100% - 250px);
	margin-left: 250px;
	transition: all 0.1s ease;
}

.mediabrain_footer {
	display: flex;
	justify-content: center;
    margin-top: auto;
    padding-top: 15px;
}

.mediabrain_footer_text {
	font-family: Open Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.admin {
	display: none;
}
a[id="fLink"]:link {
    color: #783CC6;
}

a[id="fLink"]:visited {
	color: #783CC6;
}

a[id="fLink"]:hover {
    color: #783CC6;
}

a[id="fLink1"]:link {
    color: #783CC6;
}

a[id="fLink1"]:visited {
    color: #783CC6;
}

a[id="fLink1"]:hover {
    color: #783CC6;
}