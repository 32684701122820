.main_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px; 
    width: 98%;
}
.company_logo{
    /* padding-right: 422px; */
}
.main_container_menu{
    font-family: Open Sans;
    font-style: normal;
    font-Weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex; 
    justify-content: space-between;
    width: 506px; 
    height: 27px;
}
.menu_item{
    /* padding-left: 60px; */
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.user_container{
    display: flex;
    justify-content: space-around;
    width: 265px;
    height: 27px; 
    /* padding-left: 349px;  */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019; 
    white-space: nowrap; 
}
.user_icon{
    background: #E9D9FF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF; 
    /* margin-right: 17px; */
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 84px;
    margin-top: -10px;
    opacity: 0.6;
    border: 0.5px solid #783CC6;
}
.user_text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #1E2019;
}
.logout_icon{
    /* padding-left: 30px; */
    cursor: pointer;
}
a:active{
    color: #783CC6;
}
.active{
    font-weight: 600;
}