div[id="panel1rtg-header"].MuiAccordionSummary-root.Mui-expanded {
    height: 17px;
    min-height: 17px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
